import React, { FunctionComponent } from 'react';
import { Link } from './Link';
import { Image, cloudfrontUrl } from './Image';

export interface IHeroProps {
	heading: string;
	textBlocks: string[];
	buttonText: string;
	heroImageDog: 'digby' | 'herodog2';
	children?: React.ReactNode;
}

const heroImage = (hero: string): string => {
	switch (hero) {
		case 'digby':
			return 'images/content/hero/digby.png';
		case 'herodog2':
			return 'images/content/hero/hero-dog-2.png';
		case 'new_hero':
			return 'images/content/hero/hero.png';
		default:
			return 'images/content/hero/digby.png';
	}
};

export const Hero: FunctionComponent<IHeroProps> = ({ textBlocks, buttonText, heroImageDog }: IHeroProps) => {
	return (
		<div
			className='flex flex-col items-start justify-start bg-no-repeat bg-center md:bg-cover'
			style={{
				backgroundImage: `url(${cloudfrontUrl}/${heroImage(heroImageDog)})`
			}}
		>
			<div className='bg-gradient-to-r from-black/50 to-black/0'>
				<div className='flex xl:ml-[10rem]'>
					<div className='flex flex-col gap-5 m-10 items-center justify-center md:m-20 md:w-[300px] lg:w-[500px]'>
						<Image path='images/content/hero/share_the_love.svg' alt='Share the love' className='w-[200px] md:w-[300px] lg:w-[500px]' />

						<div className='flex flex-col items-center justify-center text-center gap-5 w-[250px] lg:w-[500px]'>
							{textBlocks.map((subHeading: string, index: number) => (
								<span key={index} className='text-white text-black'>
									{subHeading}
								</span>
							))}
						</div>

						<Link href='https://www.borrowmydoggy.com/signup' looksLike='button' className='min-w-[200px] mt-5 py-5'>
							{buttonText}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
